import React from 'react';
import './Home.css';

const Home = () => (
    <section id="home" className="home-section">
        <img src="/finalhome.png" alt="home background" className="home-image" />
        <div className="home-text">
            <p>THOSE NEXT SAVES?</p>
            <p>ALL READYMADE</p>
        </div>
    </section>
);

export default Home;
