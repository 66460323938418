// src/components/Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer-section">
            <div className="footer-content">
                <p>Follow us on:
                    <a href="https://www.instagram.com/readymade_lacrosse/?igsh=bHRlcnJlOG52azVv&utm_source=qr" target="_blank" rel="noopener noreferrer"> Instagram</a>
                </p>
                <p>&copy; 2024 ReadyMade Lacrosse. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
