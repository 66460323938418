// src/components/Services.js
import React from 'react';
import './Services.css'

const Services = () => {
    return (
        <section id="services" className="services-section">
            <div className='services-content'>
                <h1>Services</h1>
                <div className="service">
                    <h2>Individual Session</h2>
                    <p>Specialized training for lacrosse goalies of all formats and levels of play.</p>
                </div>
                <div className="service">
                    <h2>Group Session</h2>
                    <p>Competitive driven environment to enhance skills and teamwork.</p>
                </div>
                <div className="service">
                    <h2>Film Session</h2>
                    <p>Breakdown of technique and defensive strategies to improve your game.</p>
                </div>
                <div className="service">
                    <h2>Professional Stringing</h2>
                    <p>Get your stick strung by a goalie, not a store, to ensure optimal performance.</p>
                </div>
            </div>
        </section>

    );
};
export default Services;
